@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.watermarked{
  background-image: url('https://rachsmartsolutions.vercel.app/static/media/rachlogowebsite.61b43425434eb0d4512f.png');
  content: "";
  position: relative;
  background-repeat: no-repeat;
  background-size: 50%;
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.9);
  background-position: 12cm 3cm; 
  background-attachment: fixed;
}


